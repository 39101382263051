/* main.css */

/* Import variables */
@import './variables.css';

/* Global styles */
body {
    font-family: var(--font-family);
    font-size: var(--font-size-base);
    background-color: var(--background-color);
    margin: 0;
    padding: 0;
}

.container {
    padding: var(--padding-base);
    margin: var(--margin-base);
}

html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
  }

/* Header styles */
.header {
    width: 100%;
    background-color: none;
    color: var(--primary-color);
    padding: 30px 30px;
    text-align: left;
}

.header .logo {
   justify-content: left; 
   font-family: var(--font-family);
   font-weight: 500;
   font-size: 50px;
}

/* Play button */
.button-style {
    background: none;
    width: 100%;
    height: 100%;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.button-style:hover {
    transform: scale(1.1);
}

.button-style-add-remove {
    display: flex;
    width: 60px;
    height: 60px;
    background: none;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
    position: relative;
}

.button-style-add-remove:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease; 
}

#refresh-button {
    background: none;
    border: none;
    margin-left: 1.8em;
    transition: transform 0.5s;
    transform-origin: center;
}

#refresh-button:disabled {
    opacity: 20%;
}

#refresh-button:hover {
    transform: scale(1.1);
}



/* Footer styles */
.footer {
    background-color: none;
    color: var(--background-color);
    position: fixed;
    padding: var(--padding-base);
    bottom: 0;
    right: 0;
    width: 100%; 
    display: flex;
    justify-content: right;
    align-items: center;
    border-top: none;
}

.footer button {
    fill: initial;
    background: none;
    border: none;
    cursor: pointer;
}


#faq-button {
    width: 70px;
    height: 70px;
    margin-right: .5em;
}

#message-button {
    width: 40px;
    height: 40px;
    margin-right: 1em;
}

#faq-button:hover {
    transform: scale(1.15);
    transition: transform 0.3s ease;
}

#message-button:hover {
    transform: scale(1.3);
    transition: transform 0.2s ease;
}

/* ASMR player styles*/

.seam-line {
    position: absolute;
    top: 0;
    right: 0;
    width: 4px;
    height: 100%;
    background-color: black;
  }

.stop-pause {
    display: flex
}

#button-style {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
}

#button-style:hover {
    transform: scale(1.15);
}

.timeline {
    display: flex;
    align-items: center;
    margin: 1em;
    height: 20px;
    width: 23em;
    background-color: #ddd;
  }

  .chapter {
    display: inline-block;
    margin-right: 5px;
    cursor: pointer;
  }

  .chapter-box {
    width: 20px;
    height: 50px;
    background-color: #000;
  }

/* Sound Parameters */

.asmr-parameter-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
    margin: 0.5em;
    margin-top: 1em;
    margin-left: 5.5em;
    width: 3em;
}

.parameter-controls {
    display: flex;
    flex-direction: column;
    align-items: right;
    padding: 0;

}

.parameter-controls label {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    color: #000;
    margin-bottom: -0.5em;
}

input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 14em;
    height: 1em;
    border-radius: 1em;
    justify-content: center;
    align-items: center;
    outline: none;
    padding: 0;
    margin: 1.1em 0;
    background: #ddd;
    transition: background 0.3s ease, transform 1s ease;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1.5em;
    height: 1.5em;
    background: #000; /* black color for thumb */
    border: 0.3em solid #fff; /* white border for thumb */
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    top: -0.25em;
    transition: transform 1s ease;
}

input[type="range"]::-webkit-slider-thumb:hover{
    transform: scale(1.35);
    transition: transform 0.3s;
}

input[type="range"]::-ms-thumb {
    width: 1.85em;
    height: 1.85;
    background: #000; /* Black color for the thumb */
    border: 0.3em solid #fff; /* White border for the thumb */
    border-radius: 50%;
    cursor: pointer;
    position: relative;
}

input[type="range"]::-ms-thumb:hover{
    transform: scale(1.3);
    transition: transform 0.3s;
    background: #007bff;
    border: 0.3em #8bc0f9;
}

input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 1em;
    cursor: pointer;
    background: transparent;
    border-radius: 1em;
}

input[type="range"]::-moz-range-track {
    width: 100%;
    height: 1em;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type="range"]::-ms-track {
    width: 100%;
    height: 1em;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}


/* Sound Selector Styles */
.sound-option {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 10px;
    text-align: center;
    vertical-align: top;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.5em;
    margin-top: -0.25em;
}

.sound-option input[type="radio"] {
    display: none;
}

.sound-option label {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
}

.sound-option label::before {
    content: "";
    display: inline-block;
    position: center;
    width: 50%;
    height: 50%;
    border-radius: 50%;
    border: 4px solid #000;
    justify-content: center;
    align-items: center;
}

.sound-option label:hover::before {
    transform: scale(1.1);
    background: #f6b19e;
    transition: transform 0.05s ease, background 0.05s ease;
}

.sound-option input[type="radio"]:checked + label::before {
    background-color: #000;
    transform: scale(1);
}

.new-row {
    clear: both;
}


/* Footer modals */
.modal {
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

/* FAQModal */
.modal {
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.creators img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.faq-image {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}


.logo {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}

/* Message Modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    position: relative;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

form label {
    display: block;
    margin-bottom: 10px;
}

form input, 
form textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

form button {
    background: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
}